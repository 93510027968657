<template>
  <div id="mainColumn1">
    <div class="column-row column-row-1-1" style="margin-bottom: 80px;">
      <div class="column column-large first last">
        <div class="column-top"></div>
        <div class="column-content">
          <div class="negative-row">
            <div class="column-round">
              <b><i></i></b><i><b></b></i><em></em>
              <div class="column-round-body">
                <div id="ContentContainer">
                  <h2>{{content.title}}</h2>
                  <component :is="content.component"></component>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { STORIES } from '@/stories/index';

  export default {
    computed: {
      id() {
        return this.$route.params.id;
      },
      content() {
        return STORIES[this.id];
      },
    }
  }
</script>

<style scoped lang="scss">
  #ContentContainer {
    max-height: 1100px;
    overflow: auto;
    margin: 8px;
  }

  #ContentContainer h2 {
    margin-bottom: 15px;
  }
</style>
